import { ReactNode, createContext, useEffect, useState } from 'react'

import { BannerData, getEnabledBanner } from '@src/components/Banner'
import VoicePlayer from '@src/components/VoicePlayer'
import { getBookmark } from './Bookmark'
import { getUserDetail, getUserQuick, User } from './User'
import { switchBodyScroll } from './Util'
import { Voice, VoiceContext } from './Voice'

export type Bookmark = { [id: string]: true }
export type ToggleState = 'close' | 'menu' | 'search'

export type Store = {
  app: {
    loading: boolean
    isFirstLoad: boolean
  }
  entity: {
    user: User | undefined
    bookmark: Bookmark
    toasts: string[]
    voice: Voice
  }
  ui: {
    banner: BannerData | undefined
    showMenu: boolean
    showContactModal: boolean
  }
  action: {
    reload: () => void
    disableBanner: () => void
    setUser: (user: User | undefined) => void
    updateBookmark: (articleIds: string[]) => void
    setShowMenu: (show: boolean) => void
    setShowContactModal: (show: boolean) => void
    addToasts: (message: string) => void
    removeToasts: () => void
    setVoiceArticleIds: (
      articleIds: string[],
      context?: VoiceContext,
      load?: boolean,
    ) => void
    setVoicePlayIndex: (playIndex: number) => void
    setVoiceIsPlaying: (playIndex: boolean) => void
  }
}
const initialStore: Store = {
  app: {
    loading: true,
    isFirstLoad: true,
  },
  entity: {
    user: undefined,
    bookmark: {},
    toasts: [],
    voice: {
      articleIds: [],
      playIndex: 0,
      isPlaying: false,
    },
  },
  ui: {
    banner: undefined,
    showMenu: false,
    showContactModal: false,
  },
  action: {
    reload: () => console.log('reload'),
    disableBanner: () => console.log('disableBanner'),
    setUser: () => console.log('setUser'),
    updateBookmark: () => console.log('updateBookmark'),
    setShowMenu: () => console.log('setShowMenu'),
    setShowContactModal: () => console.log('setShowContactModal'),
    addToasts: () => console.log('addToasts'),
    removeToasts: () => console.log('removeToasts'),
    setVoiceArticleIds: () => console.log('setVoiceArticleIds'),
    setVoicePlayIndex: () => console.log('setVoicePlayIndex'),
    setVoiceIsPlaying: () => console.log('setVoiceIsPlaying'),
  },
}

export const StoreContext = createContext(initialStore)

type StoreProviderProps = {
  children: ReactNode
}
export const StoreProvider = ({ children }: StoreProviderProps) => {
  const [loading, setLoading] = useState(true)
  const [isFirstLoad, setIsFirstLoad] = useState(true)

  const [user, setUser] = useState<User>()
  const [bookmark, setBookmark] = useState<Bookmark>({})
  const [toasts, setToasts] = useState<string[]>([])
  const [voice, setVoice] = useState<Voice>({
    articleIds: [],
    playIndex: 0,
    isPlaying: false,
  })

  const [banner, setBanner] = useState<BannerData>()
  const [showMenu, setShowMenu] = useState(false)
  const [showContactModal, setShowContactModal] = useState(false)

  const reload = () => {
    setLoading(true)
    setTimeout(() => setShowMenu(false), 500)
  }
  const disableBanner = () => setBanner(undefined)
  const updateBookmark = (articleIds: string[]) => {
    const map: Bookmark = {}
    articleIds.forEach(id => (map[id] = true))
    setBookmark(map)
  }
  const addToasts = (message: string) =>
    setToasts(toasts => [...toasts, message])
  const removeToasts = () => setToasts(toasts.slice(1))
  const setVoiceArticleIds = (
    articleIds: string[],
    context: VoiceContext | undefined = undefined,
    load: boolean = false,
  ) =>
    setVoice({
      articleIds,
      playIndex: load ? voice.playIndex : 0,
      isPlaying: articleIds.length !== 0,
      context,
    })
  const setVoicePlayIndex = (playIndex: number) =>
    setVoice(voice => ({ ...voice, playIndex }))
  const setVoiceIsPlaying = (isPlaying: boolean) =>
    setVoice(voice => ({ ...voice, isPlaying }))

  useEffect(() => switchBodyScroll(!showMenu), [showMenu])

  useEffect(() => {
    if (!loading) {
      return
    }

    if (user === undefined) {
      // 取得に失敗するとリトライして時間がかかるので、レンダリング後に取得する
      getUserQuick().then(userQuick => {
        // useTranslationは別のContextを使っている関係で、エラーが発生してしまう
        const path = window.location.pathname.replace(/^\/(ja|en)\/?/, '/')
        const locale = window.location.pathname.startsWith('/en') ? 'en' : 'ja'
        setBanner(getEnabledBanner(path, locale, userQuick.isSignIn))
        setUser(userQuick)
        getUserDetail(userQuick).then(setUser)
        getBookmark(userQuick.isSignIn, 1, 200).then(bookmark =>
          updateBookmark(bookmark.ids),
        )
      })
    }

    // loadingのopacityのアニメーションをしっかり表示するため
    setTimeout(() => {
      if (user !== undefined) {
        // useTranslationは別のContextを使っている関係で、エラーが発生してしまう
        const path = window.location.pathname.replace(/^\/(ja|en)\/?/, '/')
        const locale = window.location.pathname.startsWith('/en') ? 'en' : 'ja'
        setBanner(getEnabledBanner(path, locale, user.isSignIn))
      }
      setIsFirstLoad(false)
      setLoading(false)
    }, 500)
  }, [loading])

  return (
    <StoreContext.Provider
      value={{
        app: { loading, isFirstLoad },
        entity: { user, bookmark, toasts, voice },
        ui: { banner, showMenu, showContactModal },
        action: {
          reload,
          disableBanner,
          setUser,
          updateBookmark,
          setShowMenu,
          setShowContactModal,
          addToasts,
          removeToasts,
          setVoiceArticleIds,
          setVoicePlayIndex,
          setVoiceIsPlaying,
        },
      }}
    >
      {children}
      {!isFirstLoad && <VoicePlayer />}
    </StoreContext.Provider>
  )
}
