import { graphql, useStaticQuery } from 'gatsby'
import { Article } from '.'

type microcmsData = {
  allEnglishArticle: {
    nodes: Article[]
  }
}
export const useAllEnglishArticles = () => {
  const data = useStaticQuery<microcmsData>(graphql`
    {
      allEnglishArticle(sort: { rawPublishDate: DESC }) {
        nodes {
          blogId
          path
          title
          thumbnail {
            url
            width
            height
          }
          publishDate
          parentPage {
            id
          }
          data {
            category
            requester
          }
          enableVoice
          isMembersOnly
        }
      }
    }
  `)

  return data.allEnglishArticle.nodes
}
