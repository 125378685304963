import { useContext, useEffect, useState } from 'react'

import Link from '@atoms/Link'
import ExternalLink from '@icons/ExternalLink'
import CrossMark from '@icons/CrossMark'
import { Locale, useTranslation } from '@models/Translation'
import { StoreContext } from '@models/Store'
import { sendWarning } from '@models/Sentry'
import { getLocalData, setLocalData } from '@models/LocalData'
import { useElementVisibility } from '@models/ElementVisibility'

import * as style from './style.module.sass'

export type BannerData = {
  id: string
  title: string
  titleEnglish: string
  url: string
  urlLocale: Locale | undefined
  startDatetime: Date
  endDatetime: Date
  locales: Locale[]
  pathRegex: RegExp
  signIn: boolean | undefined
}
// 配列の先頭に近いものを優先
const bannerDataList: BannerData[] = [
  {
    id: '20250327_logo',
    title:
      '「fashion tech news」のロゴがリニューアル、新コンセプトビデオも公開',
    titleEnglish: '"fashion tech news" Unveils New Logo and Concept Video',
    url: '/info/logo',
    urlLocale: undefined,
    startDatetime: new Date('2025/03/27 12:10:00'),
    endDatetime: new Date('2025/04/02 12:00:00'),
    locales: ['ja', 'en'],
    pathRegex: /^\//,
    signIn: undefined,
  },
  {
    id: '20250327_logo',
    title:
      '「fashion tech news」のロゴがリニューアル、新コンセプトビデオも公開',
    titleEnglish: '"fashion tech news" Unveils New Logo and Concept Video',
    url: '/about',
    urlLocale: undefined,
    startDatetime: new Date('2025/03/01 12:00:00'),
    endDatetime: new Date('2025/04/02 12:00:00'),
    locales: ['ja', 'en'],
    pathRegex: /^\//,
    signIn: undefined,
  },
]

export const getEnabledBanner = (
  path: string,
  locale: Locale,
  signIn: boolean,
) => {
  for (const bannerData of bannerDataList) {
    let remove = false
    try {
      remove = getLocalData(`banner_${bannerData.id}`) === 'remove'
    } catch (e) {
      console.log(e)
      sendWarning(`ローカルデータの取得に失敗しました。${bannerData.id}`)
    }

    if (
      remove ||
      !bannerData.locales.includes(locale) ||
      !bannerData.pathRegex.test(path)
    ) {
      continue
    }
    const now = new Date()
    if (now < bannerData.startDatetime || now > bannerData.endDatetime) {
      continue
    }
    if (bannerData.signIn !== undefined && bannerData.signIn !== signIn) {
      continue
    }

    return bannerData
  }
}

const Banner = () => {
  const componentId = 'ui-banner'
  const [visibilityRef] = useElementVisibility(componentId)
  const { app, ui, action } = useContext(StoreContext)
  const { banner } = ui
  const [animation, setAnimation] = useState(!app.loading)
  const { locale } = useTranslation()

  useEffect(() => {
    if (banner !== undefined && !animation) {
      setTimeout(() => setAnimation(true), 400)
    }
  }, [banner, animation])

  const removeBanner = () => {
    setLocalData(`banner_${banner?.id}`, 'remove')
    action.disableBanner()
  }

  return (
    <div
      className={style.banner}
      data-show={banner !== undefined}
      data-gtm-click={componentId}
      data-gtm-label={banner?.id}
      ref={visibilityRef}
    >
      <div className={style.linkContainer} data-animation={animation}>
        {banner !== undefined && (
          <Link
            to={banner.url}
            locale={banner.urlLocale}
            className={style.link}
            onClick={action.reload}
          >
            <span
              dangerouslySetInnerHTML={{
                __html: locale === 'en' ? banner.titleEnglish : banner.title,
              }}
            />
            <ExternalLink />
          </Link>
        )}
      </div>
      <button onClick={removeBanner}>
        <CrossMark />
      </button>
    </div>
  )
}

export default Banner
